import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import {
  decodeEntities,
  stripHtmlTags,
  getCategoryList,
} from "../utils/helpers";
import { Carousel } from "../components/Carousel";
import RelatedProjects from "../components/Related/RelatedProjects";
import { ContactBlock } from "../components/Acf/ContactBlock";
import "./post.scss";
import ReactPlayer from "react-player";

export const ProjectPostTemplate = ({
  allCategories,
  image,
  content,
  categories,
  title,
  acf,
}) => {
  const swiperSettings = {
    id: "post-carousel",
    slidesPerView: "auto",
    spaceBetween: 48,
    grabCursor: false,
    pagination: false,
    navigation: true,
    centeredSlides: true,
  };

  return (
    <>
      <section className="single-post layout">
        {image && (
          <div className="featured-image__wrapper">
            <ImagePass src={image} />
          </div>
        )}
        <div className="single-post__wrapper">
          <div className="content">
            <span className="eyebrow">
              {categories &&
                decodeEntities(
                  getCategoryList(categories, allCategories.edges)
                )}
            </span>
            <h2>{decodeEntities(title)}</h2>

            <RenderShortcode content={content} />
          </div>
        </div>
      </section>

      {acf.video && acf.video.source_url && (
        <section className="single-post-video layout">
          <div className="single-post-video__wrapper">
            <div className="content">
              <h2>Video.</h2>
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url={acf.video.source_url}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </div>
          </div>
        </section>
      )}

      {acf.projectGallery && acf.projectGallery.length > 0 && (
        <section className="single-post-gallery">
          <div className="single-post-gallery">
            <h2>Project gallery.</h2>
            <Carousel swiperSettings={swiperSettings}>
              {Object.keys(acf.projectGallery).length > 0 &&
                acf.projectGallery?.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <ImagePass src={item.image} className="main-image" />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </section>
      )}
    </>
  );
};

const ProjectPost = (props) => {
  const { data, location } = props;
  const {
    wordpressPost: post,
    wordpressWpSettings,
    siteSettings,
    categories: allCategories,
  } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    project_category,
    date,
    acf,
  } = post;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <Header template="project" />
      <ProjectPostTemplate
        allCategories={allCategories}
        image={featured_media}
        content={content}
        categories={project_category}
        title={title}
        date={date}
        blogSlug="projects"
        acf={acf}
      />

      <RelatedProjects
        contentType="project"
        title={acf?.relatedProjects?.title || "Other projects."}
        postfilter={acf?.relatedProjects?.postfilter}
      />

      <ContactBlock />
    </>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      project_category
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        video {
          source_url
        }
        projectGallery {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1100, quality: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        relatedProjects {
          title
          postfilter {
            wordpress_id
          }
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
